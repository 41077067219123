import React, { Component } from "react";
import Images from "../../assets/images";
import axios from "axios";
import ReactHtmlParser from 'react-html-parser';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      content: "",
      social_links: []
    };
  }

  componentDidMount() {
    axios
      .get(`http://api.pallettedesigns.com/api/v1/contactusdetails?_format=json`)
      .then(res => {
        this.setState({
          title: res.data.data.title,
          content: res.data.data.content,
          social_links: res.data.data.social_links
        });
      });
  }

  render() {
    return (
      <React.Fragment>
        <footer className="container-fluid text-center">
          <div className="row">
            <div className="col-sm-4">
              <img src={Images.logo} className="img-responsive pallete_homeicon" />
            </div>

            <div className="col-sm-4">
             <SocialLinksData socialLinks = { this.state.social_links }/>
            </div>

            <div className="col-sm-4">
              <h2 class="contact-us-title">{ this.state.title }</h2>
                { ReactHtmlParser(this.state.content) }
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

const SocialLinksData = (props) => {
  return(
      <React.Fragment>
       <a href={ props.socialLinks["facebook"] ? props.socialLinks["facebook"] : "javascript:void(0)" }  target={ props.socialLinks['facebook'] ? '_blank' : '_self'} className="fa fa-facebook" />
       <a href={ props.socialLinks["twitter"] } target={ props.socialLinks['twitter'] ? '_blank' : '_self'} className="fa fa-twitter" />
       <a href={ props.socialLinks["linkedin"] } target={ props.socialLinks['linkedin'] ? '_blank' : '_self'} className="fa fa-linkedin" />
       <a href={ props.socialLinks["youtube"] } target={ props.socialLinks['youtube'] ? '_blank' : '_self'} className="fa fa-youtube" />
      </React.Fragment>
  )
}

export default Footer;