import React, { Component } from "react";
import Images from "../../assets/images/Logo_Pallette.png";
import { withRouter, NavLink } from "react-router-dom";

class Header extends Component {
  render() {
    const { location } = this.props;

    let urls = [
      { name: `Home`, url: `home`, pathname: `/home` },
      { name: `About Us`, url: `about`, pathname: `/about` },
      { name: `Services`, url: `#`, pathname: `/services` },
      { name: `Ourwork`, url: `ourwork`, pathname: `/ourwork` },
      { name: `Contact Us`, url: `contactus`, pathname: `/contactus` }
    ];
    let routerpathname = location.pathname;

    let NavLinker = urls.map((urlsitem, index) => {
      const { name, url, pathname } = urlsitem;
      let activeclass ; 
      if(url===`home` && routerpathname === "/")
        activeclass = "active"
      else if(url===`#` && (routerpathname === `/imaging` 
      || routerpathname === `/publishing` 
      || routerpathname === `/newmedia`))
        activeclass = "active"
      else
        activeclass = pathname === routerpathname ? "active" : "";
    
    //console.log(url);
      return (
        <React.Fragment key={index}>
        {
          (url===`#`) ? 
            (<li className={`nav-item dropdown`}>
            <a 
              className="nav-link dropdown-toggle" 
              href="#" 
              id="navbarDropdownMenuLink" 
              data-toggle="dropdown" 
              aria-haspopup="true" 
              aria-expanded="false">
              {name}
            </a>
            <ul 
              className="nav navbar-nav dropdown-menu pallete_dropdownmenu" 
              aria-labelledby="navbarDropdownMenuLink">
              <li className={routerpathname === `/imaging` ? "active" : ""}>
              <NavLink 
                className="dropdown-item" 
                to={{pathname: `/imaging`}}>
                Imaging
              </NavLink>
              </li>
              <li className={routerpathname === `/publishing` ? "active" : ""}>
              <NavLink 
                className="dropdown-item" 
                to={{pathname: `/publishing`}}>
                Publishing
              </NavLink>
              </li>
              <li className={routerpathname === `/newmedia` ? "active" : ""}>
              <NavLink 
                className="dropdown-item" 
                to={{pathname: `/newmedia`}}>
                New Media
              </NavLink>
              </li>
            </ul>
          </li>)
          :
            (<li className={activeclass} key={index}>
              <NavLink to={{pathname: `/${url}`}}>
                {name}
              </NavLink>
            </li>)
        }
        </React.Fragment>
      );
    });

    return (
      <React.Fragment>
        <nav className="navbar navbar-inverse">
          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#pallete_navbarheader"
              >
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <a className="navbar-brand" href="/">
                <img src={Images} className="img-responsive" />
              </a>
            </div>

            <div className="collapse navbar-collapse" id="pallete_navbarheader">
              <ul className="nav navbar-nav navbar-right">{NavLinker}</ul>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default withRouter(Header);