/**
 * Author : GopalaKrishnan.R <rgkrish183@gmail.com>
 * Project Name : Pallette Designs
 * Project Start Date : 2019-08-03
 * Delivery Date : 2019-08-05
 */

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import "react-responsive-carousel/lib/styles/carousel.min.css";

import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
    <Router>
        <App />
    </Router>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
