import React from "react";
import { Switch } from "react-router-dom";
import AsyncComponent from "./AsyncComponent";
import DefaultRoute from "./DefaultRoute";
const AsyncHomePage = AsyncComponent(() =>
    import("../containers/Home/Home")
);
const AsyncAboutPage = AsyncComponent(() =>
    import("../containers/About/About")
);
const AsyncOurWorkPage = AsyncComponent(() =>
    import("../containers/Ourwork/Ourwork")
);
const AsyncImagingPage = AsyncComponent(() =>
    import("../containers/Imaging/Imaging")
);
const AsyncPublishingPage = AsyncComponent(() =>
    import("../containers/Publishing/Publishing")
);
const AsyncNewMediaPage = AsyncComponent(() =>
    import("../containers/NewMedia/NewMedia")
);
const AsyncContactUsPage = AsyncComponent(() =>
    import("../containers/ContactUs/ContactUs")
);
const ErrorPage = AsyncComponent(() =>
    import("../components/Error/Error")
);
export default ({ childProps }) => (
    <Switch>
        <DefaultRoute path="/" exact component={AsyncHomePage} />
        <DefaultRoute path="/home" exact component={AsyncHomePage} />
        <DefaultRoute path="/about" exact component={AsyncAboutPage} />
        <DefaultRoute path="/imaging" exact component={AsyncImagingPage} />
        <DefaultRoute path="/publishing" exact component={AsyncPublishingPage} />
        <DefaultRoute path="/newmedia" exact component={AsyncNewMediaPage} />
        <DefaultRoute path="/ourwork" exact component={AsyncOurWorkPage} />
        <DefaultRoute path="/contactus" exact component={AsyncContactUsPage} />
        <DefaultRoute component={ErrorPage} path="/404" />
        <DefaultRoute component={ErrorPage} />
    </Switch>
);
