const images = {
  tick: require("./tick.jpg"),
  logo: require("./logo.png"),
  special: require("./specialilty.png"),
  arrow: require("./arrow.png"),
  top: require("./top.png"),
  btnThumb : require("./btn_thumb.png"),
  down: require("./down.png"),
};
export default images;
