import React from "react";
import { Route } from "react-router-dom";
import Layout from "../hoc/Layout";
import UserFooter from "../components/Footer/Footer";
import Header from "../components/Header/Header";

import { withRouter } from "react-router-dom";

const DefaultRoute = ({ component: Component, props: cProps, ...rest }) => {

    return (
        <Route
            {...rest}
            render={matchProps => {
                return (
                    <Layout>
                        <Header />
                        <Component {...matchProps} />
                        <UserFooter />
                    </Layout>
                )
            }}
        />
    );
};

export default withRouter(DefaultRoute);
